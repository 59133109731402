@import url('https://fonts.cdnfonts.com/css/rawline');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-[#FFFFFF] text-black;
  font-family: 'Inter', sans-serif;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
html, body, #root, .App{
  height: 100%;
}
